.content {
    background-color: #f4f4f4;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.container {
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #007bff;
    font-weight: 600;
}
.form-label {
    font-weight: 600;
    color: #495057;
}
.form-control {
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 10px 12px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}
.form-control:focus {
    border-color: #007bff;
    box-shadow: none;
}
.form-select {
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 10px 12px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}
.form-select:focus {
    border-color: #007bff;
    box-shadow: none;
}
.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}
.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}
.is-invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.is-valid {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback,
.valid-feedback {
    display: block;
    font-size: 14px;
    margin-top: 5px;
}
.invalid-feedback {
    color: #dc3545;
}
.valid-feedback {
    color: #28a745;
}
.section-title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 20px;
    color: #007bff;
}